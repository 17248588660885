import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/PokemonRNGGuides/PokemonRNGGuides/src/templates/post.js";
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { GuidePreview } from '../components/guide-preview';
import { DISCORD_URL, CONTRIBUTING_URL } from '../constants';
import CharizardImg from '../assets/box-sprites/charizard.png';
import RotomImg from '../assets/box-sprites/rotom.png';
import DittoImg from '../assets/box-sprites/ditto.png';
import GiratinaImg from '../assets/box-sprites/giratina.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What is Pokemon RNG?`}</h2>
    <p>{`RNG abuse is when players abuse a Pokemon game's random number mechanics to encounter any wild Pokemon, a legendary with any stats, or even breed a shiny Pokemon without any cheats, just the press of a button.`}</p>
    <Button variant="contained" color="primary" component="a" href={DISCORD_URL} mdxType="Button">
  Join the Discord
    </Button>
    <h2>{`How is this done?`}</h2>
    <p>{`Researchers figure out how the game's random number mechanics work and build tools to recreate the game mechanics on computers. These tools can find a desired Pokemon and tell us everything we need to obtain it.`}</p>
    <h2>{`How can I help?`}</h2>
    <p>{`Even if you're not a researcher, you can still help! This site relies on people like you to write new guides, update outdated guides, and make guides easier for people to follow!`}</p>
    <Button variant="contained" color="primary" endIcon={<CheckIcon mdxType="CheckIcon" />} component={Link} to={CONTRIBUTING_URL} mdxType="Button">
  See how to help
    </Button>
    <h2>{`Give me some guides!`}</h2>
    <p>{`Click the button on the top left of the site to see a list of guide categories!`}</p>
    <p>{`Want some fun guides? Check these out!`}</p>
    <Grid container spacing={2} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <GuidePreview name="Raid RNG" description="The easiest way to get shiny raid Pokemon in Sword and Shield" slug="retail-swsh-raid" spriteSrc={CharizardImg} mdxType="GuidePreview" />
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <GuidePreview name="Dream Radar" description="Obtain 6IV Level 5 Legends like Dialga, Lugia, and Giratina in Gen 5" slug="emulator-b2w2-dream-radar" spriteSrc={GiratinaImg} mdxType="GuidePreview" />
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <GuidePreview name="Island Scan" description="Get obscure Pokemon in the Gen 7 wild" slug="retail-usum-island-scan" spriteSrc={RotomImg} mdxType="GuidePreview" />
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <GuidePreview name="Friend Safari" description="RNG your own Dittos for breeding in X and Y" slug="pcalc-xy-friend-safari" spriteSrc={DittoImg} mdxType="GuidePreview" />
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      